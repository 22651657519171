import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import AppIcon from "../components/AppIcon";

function Home() {
  return (
    <Container
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Grid container>
        {/* Start of banner */}
        <Grid item md="3" xs="0" />
        <Grid item md="6" xs="12" justify="center">
          <h1 class="header-title">rostom.&#123;dev&#125;</h1>
          <p class="normal-text">
            A software craftsman and an Android enthusiast
          </p>
        </Grid>
        <Grid item xs="3" />
        {/* End of banner */}

        {/* Start of social media icons */}
        <Grid item md="4" xs="0" />
        <Grid item container md="4" xs="12" justify="center">
          <AppIcon
            alt="Linkedin"
            url="https://www.linkedin.com/in/omarkrostom/"
            src={process.env.PUBLIC_URL + "/assets/ic_linkedin.png"}
          />
          <AppIcon
            alt="Github"
            url="https://github.com/OmarKRostom"
            src={process.env.PUBLIC_URL + "/assets/ic_github.png"}
          />
          <AppIcon
            alt="Stackoverflow"
            url="https://stackoverflow.com/users/2350442/omar-k-rostom"
            src={process.env.PUBLIC_URL + "/assets/ic_stackoverflow.png"}
          />
        </Grid>
        <Grid item md="4" xs="0" />
        {/* End of social media icons */}

        <Grid item md="4" xs="0" />
        <Grid item container md="4" xs="12" justify="center">
          <p class="bold-text page-link">
            <a href={process.env.PUBLIC_URL + "/assets/omarkrostom-resume.pdf"}>About</a>
          </p>
          <p class="bold-text page-link">
            <a href={process.env.REACT_APP_BLOG_URL}>Blog</a>
          </p>
          <p class="bold-text page-link">
            <a href={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL}>
              Contact
            </a>
          </p>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Home;
