function AppIcon(props) {
  return (
    <a href={props.url} target="_blank">
      <img
        width="20px"
        height="20px"
        className="app-icon"
        m={5}
        alt={props.alt}
        src={props.src}
      />
    </a>
  );
}

export default AppIcon;
