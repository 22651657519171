//Libs imported
import { Box } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";

//Css Files
import "./index.css";

//Pages/Components imported
import Home from "./pages/Home.js";

ReactDOM.render(
  <React.StrictMode>
    <Home />
  </React.StrictMode>,
  document.getElementById("root")
);
